import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import ApiClient from '../helpers/ApiClient';
import './SignupForm.css';

class SignupForm extends Component {
  static propTypes = {
    signupCompleted: PropTypes.func
  }
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }
  close = () => {
    this.setState({error: null});
  }
  signup = () => {
    if (this.refs.firstNameField.value === '') {
      this.setState({error: 'First name is missing'});
      return;
    }
    if (this.refs.lastNameField.value === '') {
      this.setState({error: 'Last name is missing'});
      return;
    }
    if (this.refs.emailField.value === '') {
      this.setState({error: 'Email address is missing'});
      return;
    }
    if (this.refs.mobileField.value === '') {
      this.setState({error: 'Mobile number is missing'});
      return;
    }
    const param = {
      first_name: this.refs.firstNameField.value,
      last_name: this.refs.lastNameField.value,
      email: this.refs.emailField.value,
      mobile_phone: this.refs.mobileField.value,
      phone_preference: '0',
      type: 'Business'
    };
    if (this.refs.businessField.value !== '') {
      param.business_name = this.refs.businessField.value;
    }
    new ApiClient().post('/users', param).then(
      (data) => {
        if (data !== null && data.error) {
          this.setState({error: data.error});
        } else if (data !== null) {
          this.props.signupCompleted();
        }
      },
      (errors) => {
        if (errors !== undefined && errors.errors !== undefined && errors.errors !== null && errors.errors.first_name) {
          this.setState({error: 'First Name ' + errors.errors.first_name[0]});
        } else if (errors !== undefined && errors.errors !== undefined && errors.errors !== null && errors.errors.last_name) {
          this.setState({error: 'Last Name ' + errors.errors.last_name[0]});
        } else if (errors !== undefined && errors.errors !== undefined && errors.errors !== null && errors.errors.businessField) {
          this.setState({error: 'Business Name ' + errors.errors.businessField[0]});
        } else if (errors !== undefined && errors.errors !== undefined && errors.errors !== null && errors.errors.email) {
          this.setState({error: 'Email ' + errors.errors.email[0]});
        } else if (errors !== undefined && errors.errors !== undefined && errors.errors !== null && errors.errors.mobile_phone) {
          this.setState({error: 'Mobile Phone Number ' + errors.errors.mobile_phone[0]});
        }
      }).catch((err)=> {
        console.log(err);
      });
  }
  render() {
    return (
      <div className="signupform">
      {this.state.error === null && <div className="hide_error_box"><span>{this.state.error}</span><div className="close" onClick={this.close.bind(this)}/></div>}
      {this.state.error !== null &&  <div className="show_error_box"><span>{this.state.error}</span><div className="close" onClick={this.close.bind(this)}/></div>}
      <div className="first_name"><input hintStyle={{textAlign: ('center'), color: ('#ffffff')}} ref="firstNameField" inputStyle={{textAlign: ('center'), color: ('#ffffff')}} className="field" placeholder="First Name"/><span className="requiredMark">*</span></div>
      <div className="last_name"><input hintStyle={{textAlign: ('center'), color: ('#ffffff')}} ref="lastNameField" inputStyle={{textAlign: ('center'), color: ('#ffffff')}} className="field" placeholder="Last Name"/><span className="requiredMark">*</span></div>
      <div className="email_box"><input hintStyle={{textAlign: ('center'), color: ('#ffffff')}} ref="businessField" inputStyle={{textAlign: ('center'), color: ('#ffffff')}} className="field" placeholder="Business Name"/></div>
      <div className="email_box"><input type="email" hintStyle={{textAlign: ('center'), color: ('#ffffff')}} ref="emailField" inputStyle={{textAlign: ('center'), color: ('#ffffff')}} className="field" placeholder="Email Address"/><span className="requiredMark">*</span></div>
      <div className="input_box"><input type="number" hintStyle={{textAlign: ('center'), color: ('#ffffff')}} ref="mobileField" inputStyle={{textAlign: ('center'), color: ('#ffffff')}} className="field" placeholder="Mobile Number"/><span className="requiredMark">*</span></div>
      <div className="input_box"><RaisedButton onClick={this.signup.bind(this)} backgroundColor={'#c4105a'} className="submitButton" style={{ marginTop: '15px', lineHeight: '50px', backgroundColor: '#c4105a'}} label="Sign Up & Start Logging Deliveries Now!" labelStyle={{ textTransform: 'none', fontSize: 14 }} labelColor={'#ffffff'}
      /></div>
      </div>
    );
  }
}

export default SignupForm;
