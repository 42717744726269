import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import muiTheme from '../theme/theme.custom';
import RaisedButton from 'material-ui/RaisedButton';
import SignupForm from '../SignupForm/SignupForm';
import ReactGA from 'react-ga';
import './App.css';

export default class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    location: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      isCompleted: false
    };
  }
  componentDidMount() {
    const ReactPixel = require('react-facebook-pixel');
    ReactGA.initialize('UA-50973971-7');
    ReactGA.pageview(window.location.pathname);
    ReactPixel.default.init('408638906703045');
    ReactPixel.default.pageView();
  }
  showForm = () => {
    this.setState({isCompleted: false});
  }
  signupCompleted = () => {
    this.setState({isCompleted: true});
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://conv.indeed.com/pagead/conversion.js');
    document.head.appendChild(script);
    ReactGA.event({
      category: 'Business',
      action: 'FormSubmitted'
    });
    const ReactPixel = require('react-facebook-pixel');
    ReactPixel.default.init('408638906703045');
    ReactPixel.default.track('SubmitApplication');
  }
  render() {
    const darkFacebook = require('../images/darkFacebook.png');
    const darkInstagram = require('../images/darkInstagram.png');
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
      <div className="App">
      <a name="SIGNUP" href="#SIGNUP" className="top">&nbsp;</a>
      <Helmet title="Sherpa"/>
      <div className="header">
        <div className="headerContents">
        <a href="https://www.sherpa.net.au" target="_blank" rel="noopener noreferrer"><div className="lamma"></div></a>
        <div className="cloud"/>
        <div>
          <a href="https://www.instagram.com/sherpa_delivery" target="_blank" rel="noopener noreferrer"><div className="instagram"></div></a>
          <a href="https://www.facebook.com/getasherpa" target="_blank" rel="noopener noreferrer"><div className="facebook"></div></a>
          <div className="everyonTitle" />
        </div>
        <div className="clear"/>
        {!this.state.isCompleted && <div className="headerIntro">
          <h5>EXPRESS & SAME DAY DELIVERY</h5>
          <h1>Fast, Simple & Reliable - Just Sherpa It!</h1>
        </div>}
        <div className="shopContainer"><div className="shop"/></div>
        <div className="biggerSherpaMan"/>
        {!this.state.isCompleted && <SignupForm signupCompleted={this.signupCompleted}/>}
        {this.state.isCompleted && <div className="completed">
        <h5>THANK YOU FOR SIGNING UP</h5>
        <h1>We've Sent You an Email!</h1>
        <div className="successIntro">Please confirm your account via the link we've sent to your email, then you're ready to sign in and get started logging deliveries.</div>
        <div className="noEmail" onClick={this.showForm.bind(this)}>Didn't Receive an Email?</div>
        <div><a href="https://deliveries.sherpa.net.au/users/sign_in"><RaisedButton backgroundColor={'#c4105a'} className="submitButton" style={{ width: '200px', lineHeight: '50px', marginTop: '15px', background: '#c4105a'}} label="Go to Sign In" labelStyle={{ textTransform: 'none', fontSize: 14 }} labelColor={'#ffffff'}
        /></a></div>
        </div>}
        </div>
      </div>
      <div className="headerBottom">
        <a href="#MORE"><div className="bottomArrow"></div></a>
      </div>
      <div className="sherpaMan"/>
      <div className="feature">
      <a name="MORE" href="#MORE"> </a>
      <div className="wrap">
        <h1>Managing Your Deliveries has Never Been So Easy!</h1>
        <p className="signupDesc">We guarantee deliveries anytime between 7am - 7pm, 7 days a week within 11 major cities across Australia – Sydney, Melbourne, Brisbane, Gold Coast, Sunshine Coast, Hobart, Adelaide, Perth, Canberra, Newcastle and Cairns.</p>
      <div className="half">
          <div className="featureIcon"><div className="options" /></div>
          <h4>Multiple Delivery Options</h4>
          <div className="processDesc">2 hour, 4 hours and same day delivery options available.</div>
      </div>
      <div className="half">
      <div className="featureIcon"><div className="quote" /></div>
        <h4>Get a Quote in Seconds</h4>
        <div className="processDesc">Pricing is determined based on urgency, volume and distance of the delivery.</div>
      </div>
      <div className="half">
        <div className="featureIcon"><div className="locations" /></div>
        <h4>Sherpa It From Anywhere</h4>
        <div className="processDesc">Log, track and manage your deliveries via the web platform or the Sherpa App.</div>
      </div>
      <div className="half">
        <div className="featureIcon"><div className="loop" /></div>
        <h4>Always in the Loop</h4>
        <div className="processDesc">Track and contact your driver/s from assignment right through to delivery.</div>
      </div>
      <div className="half">
      <div className="featureIcon"><div className="payment" /></div>
      <h4>Payments Made Simple</h4>
      <div className="processDesc">Upload your Credit Card or PayPal details once, and never worry about it again!</div>
    </div>
    <div className="half">
      <div className="featureIcon"><div className="rate" /></div>
      <h4>Rate Your Sherpa Experiences</h4>
      <div className="processDesc">We want to hear from you to ensure we are maintaining a high quality of service.</div>
    </div>
      </div>
      </div>
      <div className="dummy"></div>
      <div className="requirement">
      <div className="wrap">
        <div className="requiredItem">
        <div className="left" />
        </div>
        <div className="requiredItem">
        <div className="pc" />
        </div>
        <div className="requiredItem">
        <div className="right" />
        </div>
        </div>
        <div className="clear"></div>
        <h1 className="pcIntro">Smart, Easy-to-Use Platform to keep track<br/>and manage all your deliveries!</h1>
      </div>
      <div className="signup">
        <a href="#SIGNUP"><RaisedButton backgroundColor={'#0097a9'} className="signupButton" labelStyle={{ textTransform: 'none', fontSize: 14 }} label="Sign Up & Start Logging Deliveries Now!" style={{width: '300px', lineHeight: '50px', backgroundColor: '#0097a9'}} labelColor={'#ffffff'}/></a>
      </div>
      <div className="footer">
       <div className="footerContents">
        <div className="homepage"><a href="http://www.sherpa.net.au">Sherpa</a> is a technology platform connecting independent courier professionals with businesses.</div>
        <div className="apps"><a href="https://itunes.apple.com/au/app/sherpa/id919920623" target="_blank" rel="noopener noreferrer"><div className="appLogo itunes" /></a><a href="https://play.google.com/store/apps/details?id=com.sherpa.user.prod" target="_blank" rel="noopener noreferrer"><div className="appLogo googleplay" /></a></div>
        <div className="social"><a href="https://www.facebook.com/getasherpa" target="_blank" rel="noopener noreferrer"><img className="socialFB" src={darkFacebook} alt="" /></a><a href="https://www.instagram.com/sherpa_delivery/" target="_blank" rel="noopener noreferrer"><img className="socialLogo" src={darkInstagram} alt="" /></a></div>
       </div>
      </div>
    </div>
</MuiThemeProvider>
    );
  }
}
